import React, { useEffect } from 'react'
import { AppProps } from 'next/app'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { NextSeo } from 'next-seo'
import { FirebaseAppProvider } from 'reactfire'
import TagManager from 'react-gtm-module'
import { RecoilRoot } from 'recoil'
import { ApolloProvider } from '@apollo/client'
import { CssBaseline } from '@mui/material'
import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material/styles'
import { SnackbarView } from '@components/organisms/Snackbar'
import { useApollo } from '@graphql/apolloClient'
import { isBrowserDetect } from '@libs/isBrowserDetect'
import { defaultTheme } from '@styles/defaultTheme'
import firebaseConfig from '../firebase/firebaseConfig'
import 'pure-react-carousel/dist/react-carousel.es.css'

declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme {}
}

const DynamicFirebaseAppIndividualProviders = dynamic(
  () => import('../components/common/FirebaseAppIndividualProviders'),
  { ssr: false },
)

const DynamicMyPageViewLogger = dynamic(() => import('../components/common/MyPageViewLogger'), {
  ssr: false,
})

/**
 * @param {object} initialState
 * @param {boolean} options.isServer indicates whether it is a server side or client side
 * @param {Request} options.req NodeJS Request object (not set when client applies initialState from server)
 * @param {Request} options.res NodeJS Request object (not set when client applies initialState from server)
 * @param {boolean} options.debug AdminUser-defined debug mode param
 * @param {string} options.storeKey This key will be used to preserve store in global namespace for safe HMR
 */

export function MyApp({ Component, pageProps }: AppProps & { apolloClient: any }) {
  const apolloClient = useApollo(pageProps.initialApolloState)
  const router = useRouter()

  useEffect(() => {
    if (isBrowserDetect()) {
      window.scrollTo(0, 0)
    }
  }, [router.asPath])

  useEffect(() => {
    TagManager.initialize({ gtmId: `${process.env.NEXT_PUBLIC_GTM_ID}` })
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side')
    if (jssStyles && jssStyles.parentElement) {
      jssStyles.parentElement.removeChild(jssStyles)
    }
  }, [])

  return (
    <>
      <FirebaseAppProvider firebaseConfig={firebaseConfig}>
        <NextSeo
          title={pageProps?.nextSeo?.title}
          description={pageProps?.nextSeo?.description}
          canonical={pageProps?.nextSeo?.canonical}
          openGraph={{
            url: pageProps?.nextSeo?.url,
            title: pageProps?.nextSeo?.openGraph?.title,
            description: pageProps?.nextSeo?.openGraph?.description,
            images: pageProps?.nextSeo?.openGraph?.images,
          }}
        />
        <DynamicFirebaseAppIndividualProviders>
          {/* @ts-ignore */}
          <RecoilRoot>
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={defaultTheme}>
                <ApolloProvider client={apolloClient}>
                  <CssBaseline />
                  <Component {...pageProps} />
                  <SnackbarView />
                </ApolloProvider>
              </ThemeProvider>
            </StyledEngineProvider>
            <React.Suspense fallback={<div />}>
              <DynamicMyPageViewLogger />
            </React.Suspense>
          </RecoilRoot>
        </DynamicFirebaseAppIndividualProviders>
      </FirebaseAppProvider>
    </>
  )
}

export default MyApp
