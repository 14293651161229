import { createTheme, adaptV4Theme } from '@mui/material/styles'
import { cyan, deepPurple, red, grey } from '@mui/material/colors'

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    grey: true
  }
}

declare module '@mui/material' {
  interface Color {
    main: string
    dark: string
  }
}

export const theme = createTheme(
  adaptV4Theme({
    palette: {
      primary: {
        main: deepPurple[400],
      },
      secondary: {
        main: cyan[700],
      },
      error: {
        main: red.A400,
      },
      background: {
        default: '#f8f9fa',
      },
      grey: {
        main: grey[300],
        dark: grey[400],
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
  }),
)
