import React from 'react'
import { styled } from '@mui/material/styles'
import { useRecoilValue, useResetRecoilState } from 'recoil'
import { IconButton, Snackbar } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { snackbarState } from '@store/atoms/snackbar'

const PREFIX = 'Snackbar'

const classes = {
  close: `${PREFIX}-close`,
}

const Root = styled('span')(({ theme }) => ({
  [`& .${classes.close}`]: {
    padding: theme.spacing(0.5),
  },
}))

export function SnackbarView() {
  const snackbar = useRecoilValue(snackbarState)
  const resetSnackbar = useResetRecoilState(snackbarState)

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={snackbar.isOpen}
      onClose={() => resetSnackbar()}
      autoHideDuration={8000}
      action={
        <IconButton
          aria-label="close"
          color="inherit"
          className={classes.close}
          onClick={() => resetSnackbar()}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      }
      ContentProps={{
        'aria-describedby': 'message-id',
      }}
      message={
        <Root id="message-id" data-testid="message">
          {snackbar.message}
        </Root>
      }
    />
  )
}
