// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN
const isProd =
  SENTRY_DSN ===
  'https://dce411bd5f544458a01d9cb916c3c624@o980452.ingest.sentry.io/6721607'

Sentry.init({
  dsn: SENTRY_DSN,
  replaysSessionSampleRate: isProd ? 1.0 : 0.1,
  replaysOnErrorSampleRate: isProd ? 1.0 : 0.1,
  tracesSampleRate: isProd ? 1.0 : 0.1,
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
})
